<div class="sticky-component">
  <div
    class="d-flex align-items-center justify-content-between canva-wrapper"
    [ngClass]="{ 'canva-wrapper-expanded': expanded }"
  >
    <div class="d-flex flex-grow-1 flex-wrap h-100 me-2">
      <div class="d-flex flex-grow-1 h-100">
        <a class="d-flex align-items-center bttn-icon-info mx-2" (click)="navReturn()">
           <app-icon class="fa-xl" icon="arrow-turn-left" package="regular"></app-icon>
        </a>
        <div class="separator-vr"></div>
        <div class="d-flex flex-grow-1 align-items-center">
          <div class="header-container">
            <div class="header-left">
              <ng-content select="[CanvasHeaderLeft]"></ng-content>
            </div>
            <div class="header-center">
              <ng-content select="[CanvasHeaderCenter]"></ng-content>
            </div>
            <div class="header-right">
              <ng-content select="[CanvasHeaderRight]"></ng-content>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="separator-vr"></div>

    <ng-container *ngIf="CanvasBody.childNodes.length != 0">
      <div
        class="bttn-icon-info mx-1 d-flex align-items-center"
        (click)="toggleExpand()"
      >
        <span>
          @if (expanded) {
            {{ "GL.ACTIONS.HideFilters" | translate }}
          } @else {
            {{ "GL.ACTIONS.ShowFilters" | translate }}
          }
        </span>
        <app-icon class="ps-2 fa-xl" [icon]="expanded ? 'chevrons-up' : 'chevrons-down'" package="regular"></app-icon>
      </div>
    </ng-container>
  </div>

  <div class="extra-content" #CanvasBody [class.hidden]="!expanded">
    <ng-content select="[CanvasBody]"></ng-content>
  </div>
</div>
