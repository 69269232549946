
import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationHelper } from '@app/shared/utils/nav/nav-helper';
import { IconComponent } from '../button/icon.component';

@Component({
  selector: 'app-canva-off',
  standalone: true,
  imports: [CommonModule, TranslateModule, IconComponent],
  templateUrl: './canva-off.component.html',
  styleUrl: './canva-off.component.scss'
})
export class CanvaOffComponent {
  private readonly router = inject(Router);
  @Input() urlPath?: string = '/';

  expanded: boolean = false;
  hasContent: boolean = false;

  toggleExpand (): void {
    this.expanded = !this.expanded;
  }

  navReturn (): void {
    NavigationHelper.navigateTo(this.router, this.urlPath ?? '/');
  }
}
